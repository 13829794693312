<template>
  <div class="reservation">
    <MainVisual />
    <div class="formContent">
      <Step class="twoStep" />
      <section class="formSelect">
        <h3 class="formSelect_ttl"><span>Customer Information</span></h3>
        <p class="formSelect_red">*Required fields, please make sure to fill them out before applying,</p>
        <p class="formSelect_txt">Please change your settings to allow emails from the domain “@asahi-internet.com”</p>
      </section>
      <form action="/" method="post" class="reservationForm">
        <div class="dateBox plant_id course_id date time" v-if="selectEvent">
          <p class="dateBox_ttl">Reservation date and time</p>
          <div class="dateBox_flex">
            <p class="dateBox_flex_date">{{ selectDay }}, {{ selectMonth }} {{ selectDate }}, {{ selectYear }}</p>
            <p class="dateBox_flex_time">{{ retakeDate(selectedTime) }}</p>
          </div>
          <!-- <p class="dateBox_cont" v-html="selectEvent.rsv_course_name"></p> -->
          <router-link tag="a" :to="{ path: '/eng/miyagikyo/reservation', query: { date: selectedDate, change: 'change' } }" class="dateBox_link" v-show="plant_id == 1000 && eventflg != true">Change date and time<span class="dateLink_arrow"></span></router-link>
          <router-link tag="a" :to="{ path: '/eng/yoichi/reservation', query: { date: selectedDate, change: 'change' } }" class="dateBox_link" v-show="plant_id == 1001 && eventflg != true">Change date and time<span class="dateLink_arrow"></span></router-link>
          <a @click="$router.back()" class="dateBox_link" v-show="eventflg == true || eventflg == 'true'">Change date and time<span class="dateLink_arrow"></span></a>
        </div>
        <!-- <div class="priceBox" v-if="selectEvent && paidInfo == true">
					<p class="priceBox_ttl">料金</p>
					<p class="priceBox_price" v-html="price"></p>
				</div> -->
        <div class="visitBox inputBox total_num adult_num adult_men_num adult_female_num minor_num">
          <p class="visitBox_ttl mandatory">Number of visitors*</p>
          <div class="parentKind">
            <div class="allKind">
              <div class="allKind_inner">
                <p class="twentyTtl">Over 20 years old</p>
                <div class="selectKind">
                  <div class="kindBox Box">
                    <!-- <p class="kindBox_ttl">男性</p> -->
                    <div class="peopleDiv">
                      <select name="grown" id="grown" @change="checkCom()">
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <span class="peopleDiv_arrow"></span>
                    </div>
                  </div>
                  <!-- <div class="kindBox">
										<p class="kindBox_ttl">女性</p>
										<div class="peopleDiv">
											<select name="women" id="women" @change="checkCom()">
												<option value="0">0</option>
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>
												<option value="4">4</option>
												<option value="5">5</option>
												<option value="6">6</option>
												<option value="7">7</option>
												<option value="8">8</option>
												<option value="9">9</option>
											</select>
											<span class="peopleDiv_arrow"></span>
										</div>
										<p>人</p>
									</div> -->
                </div>
              </div>
              <div class="allKind_inner underInner" v-show="minor_flg == true">
                <p class="twentyTtl">Under 20 years old</p>
                <div class="kindBox">
                  <div class="peopleDiv">
                    <select name="child" id="child" @change="checkCom()">
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                    <span class="peopleDiv_arrow"></span>
                  </div>
                </div>
              </div>
            </div>
            <p class="err">*Please select the number of visitors.</p>
            <p class="careful">Please make web reservations for a maximum of 9 people. <br class="sp" />For reservations of 10 or more people, please contact us by phone or email.</p>
          </div>
          <!-- <p class="yang">※必ず大人の方とお申込みください</p> -->
          <!-- <p class="careful">WEBでのご予約人数は合計9人以内でお願い致します。<br class="sp">10名以上のご予約はお電話にてお願い致します。</p>
					<p class="visitBox_txt">
						<span class="visitBox_txt_red" v-show="selectEvent.condition_minor_disallow_flg == 1">※20歳未満のお客様は参加できません。（お子様の同伴もご遠慮願います。）<br></span>
						<span v-show="mygflg == false">※現在は団体様のご予約は承っておりません。受付再開についてはHPにてお知らせいたします。</span>
					</p> -->
          <p class="visitBox_txt">
						<span v-show="mygflg == false">*Persons under 20 are not allowed to participate in tours alone.</span>
					</p>
        </div>
        <div id="groupNameBox" class="inputForm group_name">
          <p class="inputForm_ttl mandatory">Representative Name*</p>
          <input type="text" id="group_name" placeholder="Asahi taro" class="inputForm_input" />
          <p class="err">*Please enter the representative’s name.</p>
          <p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
        </div>

        <!-- <div id="groupKanaBox" class="inputForm group_name_kana">
          <p class="inputForm_ttl mandatory">代表者名（ふりがな）</p><span class="inputForm_ttl-note">半角スペースなどは登録不可</span>
          <input type="text" id="group_name_kana" placeholder="あさひたろう" class="inputForm_input">
          <p class="err">※代表者名（ふりがな）を入力してください</p>
          <p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
        </div> -->
        <!-- <div id="companionNameBox" class="inputForm companion_name" v-show="companion_flg">
          <p class="inputForm_ttl mandatory">同伴者様</p>
          <input type="text" id="companion_name" placeholder="朝日太郎" class="inputForm_input">
          <p class="err">※同伴者様を入力してください</p>
          <p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
        </div> -->
        <!-- <div id="companionKanaBox" class="inputForm companion_name_kana" v-show="companion_flg">
          <p class="inputForm_ttl mandatory">同伴者様（ふりがな）</p>
          <input type="text" id="companion_name_kana" placeholder="あさひたろう" class="inputForm_input">
          <p class="err">※同伴者様（ふりがな）を入力してください</p>
          <p class="not">入力された文字の種類が不正です。正しい文字で入力してください。</p>
        </div> -->
        <div id="prefBox" class="inputForm start_place_cd">
          <p class="inputForm_ttl mandatory">Country/Region*</p>
          <div class="selectDiv">
            <select name="pref" id="pref" class="inputForm_select" v-if="prefData != null">
              <option value="">Please select</option>
              <option v-for="prefInfo in prefData" :key="prefInfo.departure_id" :value="prefInfo.departure_id">{{ prefInfo.departure_name }}</option>
            </select>
            <span class="selectDiv_arrow"></span>
          </div>
          <!-- <p class="inputForm_txt inputForm_red">政府から発令された緊急事態宣言の対象地域お住いのお客様の見学ご予約・ご来場をお断りさせていたきだきます。</p> -->
          <p class="err">*Please select country</p>
        </div>
        <div id="telBox" class="inputForm represent_tel">
          <p class="inputForm_ttl mandatory">Phone number*</p>
          <input type="text" id="tel" class="inputForm_input" placeholder="+8100000000000" />
          <p class="err">*Please enter phone number.</p>
          <p class="inputForm_txt">*Please include country code</p>
          <p class="inputForm_txt">*Please enter a contactable number such as a mobile phone number.</p>
        </div>
        <div id="emailBox" class="inputForm vst_mail_address">
          <p class="inputForm_ttl mandatory" v-show="editflg == false">Email address*</p>
          <p class="inputForm_ttl" v-show="editflg">Email address*</p>
          <input type="text" id="email" class="inputForm_input" placeholder="sample@asahibeer.co.jp" v-show="editflg == false" />
          <p class="inputForm_label">{{ mail }}</p>
          <p class="err">*Please enter email address.</p>
          <p class="inputForm_txt" v-show="editflg == false">*If you have set up any measures to prevent spam emails, please make sure to allow emails from “@asahi-internet.com” to be received.</p>
        </div>
        <div id="countBox" class="inputForm vst_come_num">
          <p class="inputForm_ttl mandatory">Number of visits*</p>
          <div class="selectDiv">
            <select name="use" id="use" class="inputForm_select" v-if="visitNum != null">
              <option value="">Please select</option>
              <option v-for="coutInfo in visitNum" :key="coutInfo.vst_come_num_id" :value="coutInfo.vst_come_num_id">{{ coutInfo.vst_come_num_name }}</option>
            </select>
            <span class="selectDiv_arrow"></span>
          </div>
          <p class="err">*Please select the number of visits.</p>
        </div>
        <div id="meansBox" class="inputForm come_way_cd">
          <p class="inputForm_ttl mandatory">Method of transportation*</p>
          <div class="selectDiv">
            <select name="visit" id="visit" class="inputForm_select" v-if="comeWay != null" @change="selectChange()">
              <option value="">Please select</option>
              <option v-for="wayInfo in comeWay" :key="wayInfo.come_way_id" :value="wayInfo.come_way_id">{{ wayInfo.come_way_name }}</option>
            </select>
            <span class="selectDiv_arrow"></span>
          </div>
          <p class="err">*Please select the method of arrival.</p>
          <div class="shuttleBox" v-show="shuttle_flg">
            <a target="_blank" href="https://www.nikka.com/guide/miyagikyo/access.html" class="shuttleBox_link">Shttle bus operation scehdule</a>
            <p class="shuttleBox_txt">*Please check the access page for details.</p>
          </div>
          <div class="couseCareful" v-show="courseInfo[0].condition_transportation_note_1 != null && courseInfo[0].condition_transportation_note_1 != '' && courseInfo[0].condition_transportation_note_1 != undefined">
            <p v-html="courseInfo[0].condition_transportation_note_1"></p>
          </div>
        </div>
        <!-- <div id="unitBox" class="inputForm come_way_num" v-show="movePlan">
          <p class="inputForm_ttl mandatory">ご来場台数</p>
          <div class="selectDiv">
            <select name="unit" id="unit" class="inputForm_select">
              <option value="">選択してください</option>
              <option value="1">1台</option>
              <option value="2">2台</option>
              <option value="3">3台</option>
              <option value="4">4台</option>
              <option value="5">5台</option>
              <option value="6">6台</option>
              <option value="7">7台</option>
              <option value="8">8台</option>
              <option value="9">9台</option>
            </select>
            <span class="selectDiv_arrow"></span>
          </div>
          <p class="err">※ご来場台数を選択してください</p>
        </div> -->
        <!-- <div id="allergyBox" class="inputForm allergy" v-show="selectCourseInfo[0].chk_allergy_flg == 1">
          <p class="inputForm_ttl mandatory">アレルギー有無確認</p>
          <div class="allergyBox_flex">
            <div class="radio-content">
              <label for="allergy_no">
                <input id="allergy_no" type="radio" name="allergy" value="0">
                <div class="radio-flex">
                  <span></span>
                  アレルギー無し
                </div>
              </label>
            </div>
            <div class="radio-content">
              <label for="allergy_yes">
                <input id="allergy_yes" type="radio" name="allergy" value="1">
                <div class="radio-flex">
                  <span></span>
                  アレルギー有り
                </div>
              </label>
            </div>
          </div>
          <p class="inputForm_txt">※参加者にアレルギーをお持ちの方がいらっしゃる際は「アレルギー有り」を選択してください。<br>後日、詳細確認のためお電話を致します。</p>
          <p class="err">※アレルギー有無を選択してください</p>
        </div> -->
        <!-- <div class="inputForm represent_note" v-show="mygflg == false">
          <p class="inputForm_ttl">備考欄</p>
          <textarea name="represent_note" id="represent_note" class="inputForm_note" rows="8" placeholder="食事を伴うイベントの場合はアレルギー有無をご記入ください"></textarea>
        </div> -->
        <button type="button" class="checkBtn" @click="inputCheck()">Proceed to confirm <br />customer information</button>
      </form>
    </div>
    <Tel />

    <!-- 同意確認ボックスモーダル -->
    <div class="modal">
      <div class="checkModal">
        <!-- バツボタン -->
        <button class="closeBtn" @click="modalClose()"></button>
        <section id="overScroll" class="modalScroll">
          <h2 class="modalScroll_ttl">
            Before making<br class="sp" />
            an application,<br />please read the<br class="sp" />
            following
          </h2>

          <section class="carefulBox checkCont">
            <h3 class="carefulBox_ttl checkCont_ttl">“Notes on distillery tour reservations”</h3>
            <p class="carefulBox_txt">Please check the following before proceeding.</p>

            <section class="exampleList" v-show="mygflg == false">
              <h4 class="exampleList_ttl">&lt;For travel agencies&gt;</h4>
              <p class="exampleList_txt">
                “The reservation status on the calendar indicates the availability of groups of 1 to 9 people. <br />
                Please note that even if the number of people is within the available capacity, we will not be able to accommodate you if you use a chartered bus or microbus.”
              </p>
            </section>

            <section class="exampleList">
              <h4 class="exampleList_ttl">&lt;For individual customers&gt;</h4>
              <ul class="exampleList_list">
                <!-- <li>・場内では検温、消毒のご協力をお願いします。</li>
								<li>・当日37.5度以上の発熱があるお客様、風邪症状のあるお客様及びお連れ様も含めてご入場をお断りいたします。</li> -->
                <li>・Please cooperate with temperature checks and disinfection measures inside the facility.</li>
                <!-- <li>・Customers with a fever of 37.5 degrees Celsius or higher on the day of the visit, those with cold symptoms, and their companions will not be allowed to enter.</li> -->
                <li>・The tour is an outdoor walking tour. Please bring rain gear in case of rain.</li>
                <li>・The factory tour may be canceled due to inclement weather or natural disasters. Please note that we cannot compensate for transportation expenses in such cases.</li>
                <li>・We do not allow drunk visitors on the tour.</li>
                <li>・Please refrain from filming during guided tours.</li>
                <li>・Soft drinks are available for those who drive a car or motorcycle, come by bicycle, are under 20 years old, or are pregnant or nursing.</li>
                <li>・Alcohol tasting is limited to one drink per person.</li>
                <li>・Please note that the production line may not be in operation.</li>
                <li>・As this is a whisky distillery, please refrain from bringing in food for hygiene management purposes."</li>
                <li>・As this is a whisky distillery, please refrain from bringing pets with you for hygiene management purposes. (However, this does not apply to service animals for people with disabilities.)”</li>
              </ul>
            </section>
          </section>
          <section class="privacyBox checkCont">
            <h3 class="privacyBox_ttl checkCont_ttl">Privacy Policy</h3>
            <ol class="privacyBox_lists checkCont_lists">
              <li>1. Asahi Group will use legal and fair means to obtain personal information from customers, and will clearly specify the purpose of use as much as possible.</li>
              <li>2. Unless exceptions are allowed by law or other regulations, Asahi Group will handle the personal information obtained within the scope of the stated purpose of use for notification or disclosure.</li>
              <li>3.Asahi Group will make reasonable security measures to prevent loss, leakage, or unauthorized access, and strive to keep the personal information provided by customers accurate and up to date within the scope of the purpose of use.</li>
              <li>4.When entrusting the handling of personal information to a third party, Asahi Group will select a contractor with sufficient management standards and perform necessary and appropriate supervision.</li>
              <li>5.Except where exceptions are allowed by law or other regulations, Asahi Group will not disclose or provide personal information to third parties without obtaining prior consent from the customer.</li>
              <li>6.Asahi Group will disclose procedures for disclosure and other matters required by law or other regulations, and appropriately respond to requests from customers for inquiries or disclosure.</li>
              <li>7.Asahi Group will comply with laws and regulations regarding the protection of personal information and continue to implement and improve internal systems through employee education and other means.”</li>
            </ol>
          </section>
        </section>
        <!-- ボタン -->
        <div class="checkBox">
          <input type="checkbox" id="privacy" class="checkBox_input" name="privacy" value="I agree to the above terms and conditions." />
          <label for="privacy" class="checkBox_label" @click="checkInput()"></label>
          <p class="checkBox_txt">
            I agree to the above<br class="sp" />
            terms and conditions.
          </p>
        </div>
      </div>
      <div class="modalShadow"></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import MainVisual from "./EngMainVisual";
import Step from "./EngStep";
import Tel from "./EngTel";

export default {
  name: "RservationTop",
  components: {
    MainVisual,
    Step,
    Tel,
  },
  head: {
    title: function () {
      // 余市か宮城峡か判定
      if (location.pathname.indexOf("miyagikyo") != -1) {
        return {
          inner: 'RESERVATIONS｜MIYAGIKYO DISTILLERY｜NIKKA WHISKY',
          separator: ' ',
        }
      } else {
        return {
          inner: 'RESERVATIONS｜YOICHI DISTILLERY｜NIKKA WHISKY',
          separator: ' ',
        }
      }
    },
    meta: function () {
			return [
				{ property: 'og:locale', content: 'en_US' },
				{ property: 'og:site_name', content: 'NIKKA WHISKY' },
			]
		}
  },
  data() {
    return {
      plant_id: localStorage.getItem("plantId"),
      mygflg: false,
      editflg: false,
      mail: "",
      plantName: null,
      eventflg: this.$route.query.eventflg,
      prefData: JSON.parse(localStorage.getItem("prefData")),
      visitNum: JSON.parse(localStorage.getItem("visitNum")),
      comeWay: JSON.parse(localStorage.getItem("comeWay")),
      selectedDate: localStorage.getItem("selectedDate"),
      selectedTime: localStorage.getItem("selectedTime"),
      courseInfo: JSON.parse(localStorage.getItem("courseInfo")),
      selectCourseInfo: JSON.parse(localStorage.getItem("selectCourseInfo")),
      selectEvent: JSON.parse(localStorage.getItem("selectedEvent")),
      price: 0,
      selectYear: null,
      selectMonth: null,
      selectDate: null,
      selectDay: null,
      dayOfWeekStr: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayOfMonthStr: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      paidInfo: false,
      companion_flg: false,
      shuttle_flg: false,
      // movePlan: false,
      errors: JSON.parse(localStorage.getItem("errs")),
      // 入力確認
      error: 0,
      minor_flg: false,
      // men: 0,
      // women: 0,
      adult_num: 0,
      minor_num: 0,
      total_num: 0,
      group_name: null,
      pref: null,
      tel: null,
      email: null,
      count: null,
      visit: null,
      unit: null,
      // note: null,
      scrollPos: "",
    };
  },
  mounted() {
    window.$("body, html").scrollTop(0);

    // 宮城峡か否か判定する
    if (location.pathname.indexOf("miyagikyo") != -1) {
      this.mygflg = true;
      $(".reservation").addClass("eng");
    } else {
      this.mygflg = false;
      $(".reservation").addClass("eng");
    }

    // 20歳未満が参加できるか否か
    if (this.selectEvent.condition_minor_disallow_flg == 2) {
      this.minor_flg = true;
    } else {
      this.minor_flg = false;
    }

    // 金額
    this.price = this.selectEvent.rsv_course_fee;
    // this.price = this.price.replace(/,/g, '');
    // this.price = this.price.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,' );

    // 選択したイベントを絞り込み
    // console.log("選択したイベントを絞り込み");
    // console.log(this.courseInfo);
    this.courseInfo = this.courseInfo.filter((array) => {
      return array["course_id"] == this.selectEvent.rsv_course_id;
    });

    // リロード時余市か宮城峡か判定してそれぞれのトップに戻る
    if (localStorage.getItem("plantId") == null) {
      if (this.mygflg == true) {
        this.$router.push({ path: "/eng/miyagikyo/reservation" });
      } else {
        this.$router.push({ path: "/eng/yoichi/reservation" });
      }
    } else if (this.mygflg == true) {
      this.plantName = "Miyagikyo Distillery";
    } else {
      this.plantName = "Yoichi Distillery";
    }
    // this.$emit("updateHead");

    // ここに出すべきエラーが残っている時
    if (this.errors != null) {
      if (
        this.errors[0].errcode == 400000 ||
        this.errors[0].errcode == 400011 ||
        this.errors[0].errcode == 400020 ||
        this.errors[0].errcode == 400031 ||
        this.errors[0].errcode == 400033 ||
        this.errors[0].errcode == 400034 ||
        this.errors[0].errcode == 400040 ||
        this.errors[0].errcode == 400051 ||
        this.errors[0].errcode == 400052 ||
        this.errors[0].errcode == 400053 ||
        this.errors[0].errcode == 400054 ||
        this.errors[0].errcode == 400055 ||
        this.errors[0].errcode == 400056 ||
        this.errors[0].errcode == 400057 ||
        this.errors[0].errcode == 400058 ||
        this.errors[0].errcode == 400059
      ) {
        for (var e = 0; e < this.errors.length; e++) {
          window.$("." + this.errors[e].itemname).append('<p class="problemTxt">' + this.errors[e].message + "</p>");
        }
      }
    }

    // シャトルバスの時刻表リンクを出すかどうか判定
    for (var i = 0; i < this.comeWay.length; i++) {
      if (this.comeWay[i].come_way_name.indexOf("Shuttle") != -1) {
        this.shuttle_flg = true;
      }
    }

    var dd = new Date(new Date(this.selectedDate).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }));
    this.selectYear = new Date(new Date(this.selectedDate).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getFullYear();
    this.selectMonth = new Date(new Date(this.selectedDate).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getMonth() + 1;
    this.selectDate = new Date(new Date(this.selectedDate).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getDate();
    this.selectDay = this.dayOfWeekStr[new Date(new Date(this.selectedDate).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })).getDay()];
    this.selectMonth = this.dayOfMonthStr[dd.getUTCMonth()];

    // 有料イベントかどうかの判別
    if (this.selectEvent.rsv_course_type_name.indexOf("有料") != -1) {
      this.paidInfo = true;
    }

    // 住所のデータ整理
    for (var p = 0; p < this.prefData.length; p++) {
      if (this.prefData[p].departure_name == "") {
        this.prefData.splice(p, 1);
      }
    }

    // ご来場回数のデータ整理
    for (var v = 0; v < this.visitNum.length; v++) {
      if (this.visitNum[v].vst_come_num_name == "") {
        this.visitNum.splice(v, 1);
      }
    }

    // 入力の変更に戻ったとき、または予約内容の変更を選択された場合あらかじめ値を入力されている状態にしておく
    if (localStorage.getItem("group_name") != null) {
      $("#grown").val(localStorage.getItem("adult_num"));
      // $("#women").val(localStorage.getItem('women'));
      $("#child").val(localStorage.getItem("minor_num"));
      $("#group_name").val(localStorage.getItem("group_name"));
      $("#pref").val(localStorage.getItem("pref"));
      $("#tel").val(localStorage.getItem("tel"));
      $("#email").val(localStorage.getItem("email"));
      $("#use").val(localStorage.getItem("count"));
      $("#visit").val(localStorage.getItem("visit"));
      if (localStorage.getItem("unit") != null) {
        $("#unit").val(localStorage.getItem("unit"));
      }
      // なおかつ編集時であればメールアドレスを入力不可にする
      if (localStorage.getItem("old_tel") != null) {
        this.editflg = true;
        this.mail = localStorage.getItem("email");
      }
      // if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
      // 	let allergySet = localStorage.getItem('allergy');
      // 	if(allergySet == 1) {
      // 		$('#allergy_yes').prop('checked', true);
      // 	}else {
      // 		$('#allergy_no').prop('checked', true);
      // 	}
      // }
      // if(this.mygflg == false && localStorage.getItem('note') != null && localStorage.getItem('note') != 'null') {
      // 	$("#represent_note").val(localStorage.getItem('note'));
      // }
    }

    // 有料イベントかつ参加合計人数が二人以上の場合
    this.checkCom();
    // 移動手段で台数入力が必要なものかどうか
    this.firstChange();
  },
  methods: {
    retakeDate(time) {
      var slice = Number(time);
      var toString = String(slice);
      var slicer = ":" + toString.slice(-2) + "～";
      var result = toString.slice(0, -2) + slicer;
      return result;
    },

    checkCom() {
      // 来場者入力確認
      // this.men = $("#grown option:selected").val();
      // this.women = $("#women option:selected").val();
      // this.adult_num = Number(this.men) + Number(this.women);
      this.adult_num = $("#grown option:selected").val();
      this.minor_num = $("#child option:selected").val();
      this.total_num = Number(this.adult_num) + Number(this.minor_num);

      if (this.paidInfo == true && this.total_num >= 2 && this.mygflg == false) {
        this.companion_flg = true;
      } else {
        this.companion_flg = false;
      }
    },

    firstChange() {
      this.visit = $("#visit option:selected").val();
      for (var i = 0; i < this.comeWay.length; i++) {
        if (this.comeWay[i].come_way_id == this.visit) {
          // if(this.comeWay[i].come_way_name.match('車|貸切バス')) {
          // if(this.comeWay[i].come_way_name.indexOf('貸切バス') != -1) {
          // this.movePlan = true;
          // }
          // else {
          // this.movePlan = false;
          $("#unit").val("");
          localStorage.removeItem("unit");
          // }
        } else if (this.visit == "") {
          $("#unit").val("");
          // this.movePlan = false;
          localStorage.removeItem("unit");
        }
      }
    },

    selectChange() {
      this.visit = $("#visit option:selected").val();
      $("#unit").val("");
      localStorage.removeItem("unit");
      for (var i = 0; i < this.comeWay.length; i++) {
        if (this.comeWay[i].come_way_id == this.visit) {
          // if(this.comeWay[i].come_way_name.match('車|貸切バス')) {
          if (this.comeWay[i].come_way_name.indexOf("貸切バス") != -1) {
            this.movePlan = true;
          } else {
            this.movePlan = false;
          }
        } else if (this.visit == "") {
          this.movePlan = false;
        }
      }
    },

    inputCheck() {
      // 来場者入力確認
      this.error = 0;
      // this.men = $("#men option:selected").val();
      // this.women = $("#women option:selected").val();
      // this.adult_num = Number(this.men) + Number(this.women);
      this.adult_num = $("#grown option:selected").val();
      this.minor_num = $("#child option:selected").val();
      this.total_num = Number(this.adult_num) + Number(this.minor_num);

      // 選択したイベントが存在するかどうか
      if (this.selectEvent == null) {
        this.error++;
      }

      if (this.total_num == 0 || this.adult_num == undefined || this.minor_num == undefined) {
        this.error++;
        window
          .$(".visitBox")
          .find(".err")
          .show();
      } else if (this.total_num >= 10) {
        this.error++;
        window
          .$(".visitBox")
          .find(".careful")
          .show();
      } else {
        // 前回入力時にエラー文が発生した場合エラー文削除
        window
          .$(".visitBox")
          .find(".err")
          .hide();
      }

      // 代表者名
      this.group_name = window
        .$("#group_name")
        .val()
        .replace(/ /g, "　");
      if (this.group_name == "") {
        this.error++;
        window
          .$("#groupNameBox")
          .find(".err")
          .show();
      } else if (this.group_name.match(/^[\x20\u3000\r\n\t]*$/)) {
        this.error++;
        window
          .$("#groupNameBox")
          .find(".not")
          .show();
      } else {
        // 前回入力時にエラー文が発生した場合エラー文削除
        window
          .$("#groupNameBox")
          .find(".err")
          .hide();
      }

      // 住所
      this.pref = window.$("#pref option:selected").val();
      if (this.pref == "" || this.pref == undefined) {
        this.error++;
        window
          .$("#prefBox")
          .find(".err")
          .show();
      } else {
        // 前回入力時にエラー文が発生した場合エラー文削除
        window
          .$("#prefBox")
          .find(".err")
          .hide();
      }

      // 電話番号
      this.tel = window.$("#tel").val();
      if (this.tel == "") {
        this.error++;
        window
          .$("#telBox")
          .find(".err")
          .show();
      } else {
        // 前回入力時にエラー文が発生した場合エラー文削除
        window
          .$("#telBox")
          .find(".err")
          .hide();
      }

      // メールアドレス
      this.email = window.$("#email").val();
      if (this.email == "") {
        this.error++;
        window
          .$("#emailBox")
          .find(".err")
          .show();
      } else {
        // 前回入力時にエラー文が発生した場合エラー文削除
        window
          .$("#emailBox")
          .find(".err")
          .hide();
      }

      // ご来場回数
      this.count = window.$("#use option:selected").val();
      if (this.count == "" || this.count == undefined) {
        this.error++;
        window
          .$("#countBox")
          .find(".err")
          .show();
      } else {
        window
          .$("#countBox")
          .find(".err")
          .hide();
      }

      // ご来場手段
      this.visit = window.$("#visit option:selected").val();
      if (this.visit == "" || this.visit == undefined) {
        this.error++;
        window
          .$("#meansBox")
          .find(".err")
          .show();
      } else {
        window
          .$("#meansBox")
          .find(".err")
          .hide();
      }

      if (this.movePlan == true) {
        this.unit = window.$("#unit option:selected").val();
        if (this.unit == "") {
          this.error++;
          window
            .$("#unitBox")
            .find(".err")
            .show();
        } else {
          window
            .$("#unitBox")
            .find(".err")
            .hide();
        }
      }

      // アレルギーの有無
      // if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
      // 	this.allergy = $("#allergyBox input:checked").val();
      // 	if(this.allergy == 0 || this.allergy == 1) {
      // 		$("#allergyBox").find(".err").hide();
      // 	}else {
      // 		this.error++;
      // 		$("#allergyBox").find(".err").show();
      // 	}
      // }

      // 備考欄は必須ではない
      // if(this.mygflg == false) {
      // 	this.note = $("#represent_note").val();
      // }
      if (this.error == 0) {
        // 既に前の段階でチェックを入れていた時または、編集の時プライバシーポリシーのモーダルを表示せずに入力確認へ
        if ($("#privacy").prop("checked") == true || localStorage.getItem("old_tel") != null) {
          // お客様情報確認に移動する
          // 全てローカルストレージにはめる
          // localStorage.setItem("men", this.men);
          localStorage.setItem("adult_num", this.adult_num);
          localStorage.setItem("minor_num", this.minor_num);
          localStorage.setItem("total_num", this.total_num);
          localStorage.setItem("group_name", this.group_name);
          localStorage.setItem("pref", this.pref);
          localStorage.setItem("tel", this.tel);
          localStorage.setItem("email", this.email);
          localStorage.setItem("count", this.count);
          localStorage.setItem("visit", this.visit);
          if (this.movePlan == true) {
            localStorage.setItem("unit", this.unit);
          }
          // if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
          // 	localStorage.setItem("allergy", this.allergy);
          // }
          // if(this.mygflg == false) {
          // 	localStorage.setItem("note", this.note);
          // }

          // エラーがあった場合は一度空にする
          localStorage.removeItem("errs");
          if (this.plant_id == 1000) {
            this.$router.push({ path: "/eng/miyagikyo/reservation/confirm" });
          } else {
            this.$router.push({ path: "/eng/yoichi/reservation/confirm" });
          }
        } else {
          this.scrollPos = window.$(window).scrollTop();
          window
            .$("body")
            .addClass("fixed")
            .css({
              top: -this.scrollPos,
            });
          window.$(".modal").fadeIn();

          this.checkScroll();
          return false;
        }
      } else {
        // エラーがあった場合は各項目にエラー文字を表示させつつ一番上まで移動するようにする
        window.$("html, body").animate(
          {
            scrollTop: window.$("body").offset().top,
          },
          500
        );
      }
    },

    modalClose() {
      window
        .$("body")
        .removeClass("fixed")
        .css({
          top: 0,
        });
      window.scrollTo(0, this.scrollPos);
      window.$(".modal").fadeOut();
    },

    checkScroll() {
      const element = document.getElementById("overScroll");
      element.onscroll = () => {
        let clientHeight = element.clientHeight;
        let scrollHeight = element.scrollHeight;
        if (scrollHeight - (clientHeight + element.scrollTop) < 200) {
          window.$(".checkBox").addClass("is-active");
          window.$(".checkBox_label").css("pointer-events", "auto");
        }
      };
    },

    // 規約に同意したとき
    checkInput() {
      if (window.$("#privacy").prop("checked") == false) {
        // 飛ぶ前にbodyの固定を解除
        window
          .$("body")
          .removeClass("fixed")
          .css({
            top: 0,
          });
        // 全てローカルストレージにはめる
        // localStorage.setItem("men", this.men);
        // localStorage.setItem("women", this.women);
        localStorage.setItem("adult_num", this.adult_num);
        localStorage.setItem("minor_num", this.minor_num);
        localStorage.setItem("total_num", this.total_num);
        localStorage.setItem("group_name", this.group_name);
        localStorage.setItem("pref", this.pref);
        localStorage.setItem("tel", this.tel);
        localStorage.setItem("email", this.email);
        localStorage.setItem("count", this.count);
        localStorage.setItem("visit", this.visit);
        if (this.movePlan == true) {
          localStorage.setItem("unit", this.unit);
        }
        // if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
        // 	localStorage.setItem("allergy", this.allergy);
        // }
        // if(this.mygflg == false) {
        // 	localStorage.setItem("note", this.note);
        // }

        // エラーがあった場合は一度空にする
        localStorage.removeItem("errs");
        // お客様情報確認に移動する
        if (this.plant_id == 1000) {
          this.$router.push({ path: "/eng/miyagikyo/reservation/confirm" });
        } else {
          this.$router.push({ path: "/eng/yoichi/reservation/confirm" });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.sp {
  display: none;
}

// 768以下
.picxel .sp {
  display: block;
}

.formContent {
  width: 960px;
  margin: 0 auto;
  padding: 50px 0 60px;
}

// 768以下
.picxel .formContent {
  width: calc(100% - 50px);
}

.win.en .mainFont .formSelect {
  margin-top: 90px;
  &_ttl {
    // width: 178px;
    width: auto;
    margin: 0 auto;
    padding-bottom: 15px;
    font-size: 34px;
    letter-spacing: 0.04em;
    text-align: center;
    font-weight: 100;
    // border-bottom: 2px solid #000;
		border-bottom: none;
    span {
      display: inline-block;
      border-bottom: 2px solid #000;
    }
  }
  &_red {
    margin-top: 76px;
    font-size: 16px;
    line-height: 2 !important;
    color: #df0202;
  }
  &_txt {
    font-size: 16px;
    line-height: 2 !important;
  }
}

// 768以下
.picxel.win .mainFont .formSelect {
  margin-top: 43px;
  &_ttl {
    width: 126px;
    font-size: 24px;
    padding-bottom: 15px;
  }
  &_red {
    margin-top: 51px;
    font-size: 13px;
  }
  &_txt {
    font-size: 13px;
  }
}

.reservationForm {
  width: 700px;
  margin: 0 auto;
}

// 768以下
.picxel .reservationForm {
  width: 100%;
}

.dateBox {
  position: relative;
  margin: 60px auto;
  &_ttl {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  &_flex {
    display: flex;
    font-size: 18px;
    margin-bottom: 15px;
    &_date {
      margin-right: 5px;
    }
  }
  &_cont {
    font-size: 18px;
  }
  &_link {
    position: absolute;
    top: 65px;
    right: 15px;
    font-size: 18px;
    color: #ba0000;
    text-decoration: underline;
    cursor: pointer;
    .dateLink_arrow {
      position: relative;
      width: 7px;
      height: 14px;
      margin-left: 5px;
      &::before {
        content: "";
        width: 7px;
        height: 7px;
        border: 0px;
        border-top: solid 2px #ba0000;
        border-right: solid 2px #ba0000;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
      }
    }
  }
}

// 768以下
.picxel .dateBox {
  margin: 42px auto 47px;
  &_ttl {
    font-size: 14px;
    margin-bottom: 15px;
  }
  &_flex {
    font-size: 14px;
    margin-bottom: 10px;
    &_date {
      margin-right: 5px;
    }
  }
  &_cont {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &_link {
    position: static;
    font-size: 15px;
    .dateLink_arrow {
      width: 7px;
      height: 14px;
      &::before {
        width: 7px;
        height: 7px;
      }
    }
  }
}

.inputBox {
  margin-bottom: 40px;
}

// 768以下
.picxel .inputBox {
  margin-bottom: 35px;
}

// 料金
.priceBox {
  &_ttl {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_price {
    font-size: 18px;
    margin-bottom: 60px;
  }
}

// 768以下
.picxel .priceBox {
  &_ttl {
    font-size: 14px;
    margin-bottom: 14px;
  }
  &_price {
    font-size: 13px;
    margin-bottom: 35px;
  }
}

// 来場者数
.visitBox {
  &_ttl {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    // &::after {
    // 	content: "※";
    // 	position: absolute;
    // 	font-size: 8px;
    // 	color: #df0202;
    // 	top: 0;
    // }
  }
  &_txt {
    font-size: 16px;
    line-height: 1.5 !important;
    color: #656565;
    margin-top: 10px;
    span {
      font-size: 14px;
    }
    &_red {
      color: #ff0000;
    }
  }
}

// 768以下
.picxel .visitBox {
  &_ttl {
    &::after {
      font-size: 8px;
    }
    font-size: 14px;
    margin-bottom: 20px;
  }
  &_txt {
    font-size: 13px;
    margin-top: 10px;
    span {
      font-size: 12px;
    }
  }
}

.allKind {
  display: flex;
  // gap:12px;
  // gap:40px;
  flex-wrap: wrap;
  // justify-content: space-between;
  column-gap: 20px;
  .twentyTtl {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .-pc {
    display: block;
  }
}
.allKind > div {
  display: flex;
  width: 33.3%;
  // flex-direction: column;
  .twentyTtl {
    display: flex;
    align-items: center;
    // flex-grow: 1;
  }
}

// 768以下
.picxel .allKind {
  display: block;
  .twentyTtl {
    font-size: 13px;
    margin-top: 14px;
    margin-bottom: 0;
  }
  .-pc {
    display: none;
  }
}
.picxel .allKind > div {
  justify-content: space-between;
  width: auto;
  max-width: 310px;
}

.selectKind {
  display: flex;
  // margin-right: 58px;
}

// 768以下
.picxel .selectKind {
  margin-right: 0;
}

.kindBox {
  display: flex;
  align-items: center;
  p {
    font-size: 16px;
  }
  .peopleDiv {
    position: relative;
    margin: 0 10px 0 15px;
    &_arrow {
      position: absolute;
      top: 50%;
      right: 11px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 5px 0 5px;
      border-color: #000 transparent transparent transparent;
    }
  }
  select {
    font-size: 16px;
    background-color: #fff;
    padding: 22px 41px 22px 11px;
    border: 1px solid #e5e5e5;
    outline: none;
    -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
    -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
    appearance: none; /* 標準のスタイルを無効にする */
    &::-ms-expand {
      /* select要素のデザインを無効にする（IE用） */
      display: none;
    }
  }
}

// 768以下
.picxel .kindBox {
  p {
    font-size: 12px;
  }
  .peopleDiv {
    margin: 0;
    &_arrow {
      right: 16px;
      border-width: 8px 5px 0 5px;
    }
  }
  select {
    font-size: 16px;
    transform: scale(0.75);
    margin: 0;
    padding: 14px 42px 14px 11px;
  }
}

.menBox {
  margin-right: 40px;
}

// 768以下
.picxel .menBox {
  margin-right: 26px;
}

// テキスト系の入力フォーム
.inputForm {
  margin-bottom: 40px;
  &_ttl {
    position: relative;
    font-size: 18px;
    letter-spacing: 0.05em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_ttl-note {
    font-size: 13px;
    margin-left: 16px;
  }
  // .mandatory::after {
  // 	content: "※";
  // 	position: absolute;
  // 	font-size: 8px;
  // 	color: #df0202;
  // 	top: 0;
  // }
  &_txt {
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-top: 10px;
    color: #656565;
  }
  &_red {
    color: #ff0000;
  }
  &_input {
    width: 100%;
    font-size: 16px;
    padding: 22px 20px;
    background-color: #fbfbfb;
    border: 1px solid transparent;
    outline: none;
    box-sizing: border-box;
    &.error {
      border-color: #df0202;
    }
    &::placeholder {
      color: #adadad;
    }
  }
  &_label {
    font-size: 16px;
  }
  .selectDiv {
    position: relative;
    width: 340px;
    &_arrow {
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 5px 0 5px;
      border-color: #000 transparent transparent transparent;
    }
  }
  &_select {
    font-size: 16px;
    color: #000;
    background-color: #fff;
    outline: none;
    border: 1px solid #e5e5e5;
    width: 340px;
    box-sizing: border-box;
    padding: 22px 20px;
    -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
    -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
    appearance: none; /* 標準のスタイルを無効にする */
    &::-ms-expand {
      /* select要素のデザインを無効にする（IE用） */
      display: none;
    }
    &.error {
      border-color: #df0202;
    }
  }
  // &_note {
  // 	width: 100%;
  // 	font-size: 16px;
  // 	line-height: 1.8 !important;
  // 	padding: 22px 20px;
  // 	background-color: #fbfbfb;
  // 	border: 1px solid transparent;
  // 	outline: none;
  // 	box-sizing: border-box;
  // 	@media screen and (max-width: 768px) {
  // 		font-size: 32px;
  // 		transform: scale(0.75);
  // 		width: 133%;
  // 		margin: -56px 0 0 -17%;
  // 		padding: 55px 50px;
  // 	}
  // 	&.error{
  // 		border-color: #df0202;
  // 	}
  // 	&::placeholder {
  // 		color: #ADADAD;
  // 	}
  // }
}

.group_name {
  .inputForm_ttl {
    display: inline-block;
  }
}
.group_name_kana {
  .inputForm_ttl {
    display: inline-block;
  }
}

// 768以下
.picxel .inputForm {
  margin-bottom: 20px;
  &_ttl {
    font-size: 14px;
    margin-bottom: 3px;
  }
  &_ttl-note {
    font-size: 11px;
    margin-left: 16px;
  }
  .mandatory::after {
    font-size: 8px;
  }
  &_txt {
    font-size: 12px;
    margin-top: 10px;
  }
  &_input {
    font-size: 16px;
    transform: scale(0.875);
    width: 114%;
    margin-left: -7%;
    padding: 18.62px 19.95px;
  }
  &_label {
    font-size: 14px;
  }
  .selectDiv {
    right: 22px;
    border-width: 8px 5px 0 5px;
  }
  &_select {
    font-size: 16px;
    transform: scale(0.75);
    width: 133%;
    margin-left: -12%;
    padding: 18.62px 19.95px;
  }
}

.err {
  display: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  letter-spacing: 0.05em;
  color: #df0202;
}

// 768以下
.picxel .err {
  font-size: 12px;
  margin-top: 5px;
}

.not {
  display: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  letter-spacing: 0.05em;
  color: #df0202;
}

// 768以下
.picxel .not {
  font-size: 12px;
  margin-top: 5px;
}

// .yang {
// 	display: none;
// 	font-size: 14px;
// 	font-weight: bold;
// 	margin-top: 5px;
// 	letter-spacing: 0.05em;
// 	color: #df0202;
// 	@media screen and (max-width: 768px) {
// 		font-size: 24px;
// 		margin-top: 10px;
// 	}
// }

.careful {
  display: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  letter-spacing: 0.05em;
  color: #df0202;
}

// 768以下
.picxel .careful {
  font-size: 12px;
  margin-top: 5px;
}

.problemTxt {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  letter-spacing: 0.05em;
  color: #df0202;
}

// 768以下
.picxel .problemTxt {
  font-size: 12px;
  margin-top: 5px;
}

.shuttleBox {
  display: flex;
  margin-top: 10px;
  &_link {
    position: relative;
    font-size: 14px;
    text-decoration: none;
    color: #656565;
    padding-bottom: 1px;
    margin-right: 8px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #656565;
      bottom: 0;
      left: 0;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 0 -1px 3px;
      background-image: url(../assets/img/tabLink.png);
      background-size: cover;
    }
  }
  &_txt {
    font-size: 14px;
    color: #656565;
  }
}

// 768以下
.picxel .shuttleBox {
  display: block;
  &_link {
    font-size: 12px;
    margin-top: 10px;
    margin-right: 0;
    padding-bottom: 1px;
    &::after {
      width: 11px;
      height: 11px;
      margin: 0 0 -1px 3px;
    }
  }
  &_txt {
    font-size: 10px;
    margin-top: 6px;
  }
}

// 移動手段下に表示させるテキスト
.couseCareful {
  font-size: 14px;
  margin-top: 10px;
}

.picxel .couseCareful {
  font-size: 12px;
  margin-top: 10px;
}

// アレルギーラジオボタン
.allergyBox_flex {
  display: flex;
  align-items: center;
  .radio-content {
    margin-right: 30px;
    margin-bottom: 15px;
    label {
      margin-bottom: 0;
      display: inline-block;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked + .radio-flex {
      font-weight: 700;
      color: #af9754;
      span {
        &::after {
          display: block;
        }
      }
    }
    .radio-flex {
      display: flex;
      align-items: center;
      font-size: 15px;
      cursor: pointer;
      span {
        position: relative;
        margin-right: 10px;
        background-color: #fff;
        border: 1px solid #a7a7a7;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &::after {
          content: "";
          display: none;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #af9754;
          border-radius: 50%;
        }
      }
    }
  }
}

.picxel .allergyBox_flex {
  display: block;
  .radio-content {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .radio-flex {
    font-size: 14px;
    span {
      margin-right: 10px;
      width: 18px;
      height: 18px;
      &::after {
        width: 10px;
        height: 10px;
      }
    }
  }
}

// 送信確認ボタン
.checkBtn {
  display: block;
  position: relative;
  font-size: 18px;
  // line-height: 1 !important;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
  border: none;
  outline: none;
  background-color: #000;
  padding: 22px 0;
  width: 320px;
  margin: 80px auto 0;
  cursor: pointer;
  line-height: 1.7;
  &::after {
    content: "";
    width: 7px;
    height: 7px;
    border: 0px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: rotate(45deg) translateY(-50%);
    margin-top: -2px;
  }
}

// 768以下
.picxel .checkBtn {
  width: 100%;
  font-size: 15px;
  margin-top: 40px;
  padding: 22px 0;
  &::after {
    width: 7px;
    height: 7px;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
  }
}

// モーダル
.modal {
  display: none;
}

.checkModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 80%;
  padding: 73px 100px 70px;
  background-color: #fff;
  z-index: 999;
  box-sizing: border-box;

  .modalScroll {
    width: 100%;
    height: calc(100% - 93px);
    padding: 0 20px 0 40px;
    box-sizing: border-box;
    overflow-y: scroll;
    &_ttl {
      font-size: 32px;
      text-align: center;
      margin-bottom: 41px;
      transform: rotate(0.03deg);
      -moz-transform: rotate(0.03deg);
      -ms-transform: rotate(0.03deg);
      -o-transform: rotate(0.03deg);
      -webkit-transform: rotate(0.03deg);
    }
    &_red {
      font-size: 14px;
      font-weight: 500;
      color: #df0202;
      text-align: center;
      margin-bottom: 30px;
      padding: 15px;
      background-color: #fff9f8;
      border: 1px solid #df0202;
      border-radius: 8px;
    }

    .checkCont {
      &_ttl {
        font-size: 20px;
        letter-spacing: 0.1em;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 10px;
        margin-bottom: 15px;
        transform: rotate(0.03deg);
        -moz-transform: rotate(0.03deg);
        -ms-transform: rotate(0.03deg);
        -o-transform: rotate(0.03deg);
        -webkit-transform: rotate(0.03deg);
      }
      &_lists {
        font-size: 16px;
        list-style: none;
        transform: rotate(0.03deg);
        -moz-transform: rotate(0.03deg);
        -ms-transform: rotate(0.03deg);
        -o-transform: rotate(0.03deg);
        -webkit-transform: rotate(0.03deg);
        li {
          line-height: 2 !important;
        }
        &_left {
          padding-left: 16px;
        }
      }
    }

    // 感染予防対策のお願い
    .measuresBox {
      color: #df0202;
    }

    // プライバシーポリシー
    .privacyBox {
      margin-top: 36px;
    }

    // 見学ご予約時のご注意事項
    .carefulBox {
      margin-top: 36px;
      &_txt {
        font-size: 16px;
        line-height: 2 !important;
        transform: rotate(0.03deg);
        -moz-transform: rotate(0.03deg);
        -ms-transform: rotate(0.03deg);
        -o-transform: rotate(0.03deg);
        -webkit-transform: rotate(0.03deg);
      }

      .exampleList {
        font-size: 16px;
        transform: rotate(0.03deg);
        -moz-transform: rotate(0.03deg);
        -ms-transform: rotate(0.03deg);
        -o-transform: rotate(0.03deg);
        -webkit-transform: rotate(0.03deg);
        &_ttl {
          line-height: 2 !important;
        }
        &_txt {
          line-height: 2 !important;
        }
        &_list {
          list-style: none;
          li {
            line-height: 2 !important;
          }
        }
      }
    }
  }
  .prohibited {
    padding: 32px 0;
    &__list {
      display: flex;
      gap: 0 12px;
      justify-content: center;
    }
    &__item {
      width: 86px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// 768以下
.picxel .checkModal {
  width: calc(100% - 50px);
  padding: 63px 0 30px;
  .modalScroll {
    padding: 0;
    &_ttl {
      font-size: 24px;
      margin-bottom: 21px;
      padding: 0 10px;
    }
    &_red {
      font-size: 12px;
      width: calc(100% - 40px);
      margin: 0 auto 20px;
      padding: 5px 15px;
      background-color: #fff9f8;
      border-radius: 8px;
    }

    .checkCont {
      &_ttl {
        font-size: 16px;
        padding-bottom: 8.5px;
        margin-bottom: 11.5px;
        padding: 0 20px;
      }
      &_lists {
        font-size: 12px;
        padding: 0 20px;
        &_left {
          padding-left: 12px;
        }
      }
    }

    // プライバシーポリシー
    .privacyBox {
      margin-top: 30px;
    }

    // 見学ご予約時のご注意事項
    .carefulBox {
      margin-top: 30px;
      &_txt {
        font-size: 12px;
        padding: 0 20px;
      }

      .exampleList {
        font-size: 12px;
        padding: 0 20px;
        margin-bottom: 30px;
      }
    }
  }

  .prohibited {
    &__item {
      width: 72px;
    }
  }
}

.pc_display {
  display: inline;
}

// 768以下
.picxel .pc_display {
  display: none;
}

// 同意するチェックボックス
.checkBox {
  display: flex;
  justify-content: center;
  width: 100%;
  // background-color: rgba($color: #af9754, $alpha: 0.8);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 22px 0;
  margin-top: 33px;
  align-items: center;
  transition: background 0.3s ease-out;
  // チェックボックスカスタマイズ
  &_input[type="checkbox"] {
    display: none;
  }
  &_label {
    position: relative;
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    pointer-events: none;
    margin-right: 12px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_input[type="checkbox"]:checked + &_label::before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 6px;
    box-sizing: border-box;
    border-bottom: 2px solid #3949ab;
    border-left: 2px solid #3949ab;
    top: 30%;
    left: 50%;
    transform: rotate(-45deg) translate(-50%, -50%);
  }
  &_txt {
    font-size: 18px;
    color: #fff;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
}

.checkBox.is-active {
  background-color: #000;
}

// 768以下
.picxel .checkBox {
  padding: 28px 0;
  margin-top: 40px;
  &_label {
    width: 27px;
    height: 27px;
    border-radius: 4px;
    margin-right: 12px;
  }
  &_input[type="checkbox"]:checked + &_label::before {
    width: 10px;
    height: 6px;
    border-bottom: 2px solid #3949ab;
    border-left: 2px solid #3949ab;
  }
  &_txt {
    font-size: 15px;
  }
}

// バツボタン
.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  border: none;
  background: none;
  width: 56px;
  height: 56px;
  color: #fff;
  background-color: #000;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
  z-index: 9999;
  &:hover {
    opacity: 0.7;
  }
  &::before {
    content: "";
    width: 36px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    content: "";
    width: 36px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

// 768以下
.picxel .closeBtn {
  width: 36px;
  height: 36px;
  &::before {
    width: 16px;
    height: 2px;
  }
  &::after {
    width: 16px;
    height: 2px;
  }
}

// モーダルの後ろの影
.modalShadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.8);
  z-index: 99;
}
.en .inputForm{
	.mandatory{
		&::after{
			display: none;
		}
	}
}
.en .visitBox {
	&_ttl {
		&::after{
			display: none;
		}
	}
}
</style>
